import { default as localesModule, initializeLocales } from './locales';
import { default as accountModule, initializeAccount } from './account';
import { default as videoModule } from './video';
import { initializeGtm } from './gtm';
import { initializeTrack } from './track';
import { default as breadcrumbModule, initializeBreadcrumbs } from './breadcrumb';
import { default as localizationTokenModule, initializeLocalizations } from './localizationToken';
import { default as countryModule, initializeCountry } from './country';
import { default as contextModule, initializeContextData } from './context';
import { default as cartModule, initializeCart } from './cart';
import { default as cartAnalyticsModule } from './cartAnalytics';
import { default as numericStepperModule } from './numericStepper';
import { default as dropDownModule } from './dropDown';
import { default as addedToCartModule } from './addedToCartModal';
import { default as subscriptionTermsConflictModule } from './subscriptionTermsConflictModal';
import { default as chatModule, initializeChat } from './chat';
import { createHrefListener } from '../../universal-components/src/utils';
import { useLocalStorage } from '../../universal-components/src/Checkout/hooks/useLocalStorage';
import { CHECKOUT_LOCAL_DATA_KEY } from '../../universal-components/src/constants';
import logger from './logger';

export let didInitLocales = false;
export let didInitAccount = false;
export let didInitTrack = false;
export let didInitGTM = false;
export let didInitBreadcrumbs = true;
export let didInitCountry = false;
export let didInitCart = false;
export let didInitLocalizations = false;
export let didInitContextData = false;
export let didInitLiveChat = false;

export function init(config = {}) {
  const { locales, account, gtm, track, breadcrumbs, country, cart, localizations, context, chat } = config;
  
  createHrefListener((newHref) => {
    // Check if the new URL does not include "/checkout"
    const isNotOnCheckoutPage = !newHref.includes('/checkout');
    const [_, __, resetCheckoutLocalData] = useLocalStorage(CHECKOUT_LOCAL_DATA_KEY);
  
    // If the user navigates away from the checkout page, reset checkout local data to prevent unnecessary persistence.
    if (isNotOnCheckoutPage) {
      resetCheckoutLocalData(); 
    }
  });

  if (locales) {
    initializeLocales(locales);
    didInitLocales = true;
  }

  // If account === true, load the user with default params
  if (account === undefined || account === true) {
    initializeAccount().then(() => {
      // Once the account module is fully initialized, initialize the shopping cart (cart requires login info).
      initializeCartWithDependencies(cart, country);
    });
    didInitAccount = true;
  }
  // If account is configured, load the user with the passed in configuration. Make sure it's
  // not an array because that will break things!!
  else if (typeof account === 'object' && !Array.isArray(account)) {
    initializeAccount({ ...account }).then(() => {
      // Once the account module is fully initialized, initialize the shopping cart (cart requires login info).
      initializeCartWithDependencies(cart, country);
    });
    didInitAccount = true;
  }
  // Otherwise it's a bad input and warn the developer that they are naughty
  else {
    logger.warn('Account not initialized!');
  }

  /**
   * If gtm (Google Tag Manager) is enabled we are going to use that configuration for tracking analytics
   * If both gtm and track configuration objects are defined we are going to use the gtm config
   */
  if (gtm) {
    initializeGtm({ ...gtm });
    didInitGTM = true;
  } else if (track) {
    if (typeof track === 'object') {
      initializeTrack({ ...track });
      didInitTrack = true;
    }
  }

  if (breadcrumbs) {
    initializeBreadcrumbs({ ...breadcrumbs });
    didInitBreadcrumbs = true;
  }

  // Initialize the country selector in the case where the cart isn't initialized
  if (country && !cart) {
    initializeCountry({ ...country }).then(() => {
      didInitCountry = true;
    });
  }

  if (localizations) {
    initializeLocalizations({ ...localizations });
    didInitLocalizations = true;
  }

  if (context) {
    initializeContextData({ ...context });
    didInitContextData = true;
  }

  if (chat) {
    initializeChat({ ...chat });
    didInitLiveChat = true;
  }
}

/**
 * Initialize the cart and ensure that the country module is initialized first. The country module is required to be fully initialized
 * prior to initializing the cart due to the fact that we need the users geolocation to create a shopping cart.
 *
 * @param {*} cart configuration passed via the init call on the disw-utils.
 * @param {*} country configuration passed via the init call on the disw-utils.
 */
function initializeCartWithDependencies(cart, country) {
  if (cart) {
    // The cart requires the country be fully initialized prior to initializing the cart.
    initializeCountry({ ...country }).then(() => {
      didInitCountry = true;
      initializeCart({ ...cart }).then(() => {
        didInitCart = true;
      });
    });
  }
}

export function getDidInitLocales() {
  return didInitLocales;
}

export function getDidInitAccount() {
  return didInitAccount;
}

export function getDidInitTrack() {
  return didInitTrack;
}

export function getdidInitGTM() {
  return didInitGTM;
}

export function getDidInitBreadcrumbs() {
  return didInitBreadcrumbs;
}

export function getDidInitLocalizations() {
  return didInitLocalizations;
}

export function getDidInitCountrys() {
  return didInitCountry;
}

export function getDidInitCart() {
  return didInitCart;
}

export function getDidInitContextData() {
  return didInitContextData;
}

export function getDidInitLiveChat() {
  return didInitLiveChat;
}

export const account = { ...accountModule };
export const locales = { ...localesModule };
export const video = { ...videoModule };
export const breadcrumbs = { ...breadcrumbModule };
export const context = { ...contextModule };
export const localizationTokens = { ...localizationTokenModule };
export const country = { ...countryModule };
export const cart = { ...cartModule };
export const cartAnalytics = { ...cartAnalyticsModule };
export const numericStepper = { ...numericStepperModule };
export const dropDown = { ...dropDownModule };
export const addedToCartModal = { ...addedToCartModule };
export const subscriptionTermsConflictModal = { ...subscriptionTermsConflictModule };
export const chat = { ...chatModule };
